// import React, { Component, Suspense } from 'react'
import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { RequireAuth } from './RequireAuth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import axios from 'axios'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const { user } = useAuthenticator((context) => [context.user])
  if (user !== undefined) {
    axios.defaults.headers.common['Authorization'] = user
      .getSignInUserSession()
      .getIdToken()
      .getJwtToken()
    console.log('set new user token  ')
  }

  console.log(authStatus)
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            path="*"
            name="Home"
            element={
              <>
                <RequireAuth>
                  {authStatus === 'authenticated' ? <DefaultLayout /> : <br />}
                </RequireAuth>
                {authStatus !== 'authenticated' ? <h1>loading</h1> : <br />}
              </>
            }
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
