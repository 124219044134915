import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'

import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import { Amplify } from 'aws-amplify'

let redirectSignIn, redirectSignOut

if (window.location.hostname.indexOf('localhost') >= 0) {
  redirectSignIn = 'http://localhost:3000/'
  redirectSignOut = 'http://localhost:3000/'
} else {
  redirectSignIn = 'https://c.zhangdaqian.net'
  redirectSignOut = 'https://c.zhangdaqian.net'
}

const awsconfig = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:655f8503-cdb1-4b1d-956f-bd36a42495f6',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_LWZHpAiXH',
  aws_user_pools_web_client_id: '3fpqucj6f906aioh8kpqhis901',
  oauth: {
    domain: 'oauthdemo0ce32532-0ce32532-dev.auth.us-west-2.amazoncognito.com',
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: redirectSignIn,
    redirectSignOut: redirectSignOut,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}

Amplify.configure(awsconfig)

createRoot(document.getElementById('root')).render(
  <Authenticator.Provider>
    <Provider store={store}>
      <App />
    </Provider>
  </Authenticator.Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
